exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-en-english-teaching-jobs-barcelona-jsx": () => import("./../../../src/pages/en/english-teaching-jobs-barcelona.jsx" /* webpackChunkName: "component---src-pages-en-english-teaching-jobs-barcelona-jsx" */),
  "component---src-pages-en-thank-you-jsx": () => import("./../../../src/pages/en/thank-you.jsx" /* webpackChunkName: "component---src-pages-en-thank-you-jsx" */),
  "component---src-templates-about-school-jsx": () => import("./../../../src/templates/about-school.jsx" /* webpackChunkName: "component---src-templates-about-school-jsx" */),
  "component---src-templates-about-us-jsx": () => import("./../../../src/templates/about-us.jsx" /* webpackChunkName: "component---src-templates-about-us-jsx" */),
  "component---src-templates-accomodation-jsx": () => import("./../../../src/templates/accomodation.jsx" /* webpackChunkName: "component---src-templates-accomodation-jsx" */),
  "component---src-templates-activities-jsx": () => import("./../../../src/templates/activities.jsx" /* webpackChunkName: "component---src-templates-activities-jsx" */),
  "component---src-templates-all-courses-jsx": () => import("./../../../src/templates/all-courses.jsx" /* webpackChunkName: "component---src-templates-all-courses-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-business-english-course-jsx": () => import("./../../../src/templates/business-english-course.jsx" /* webpackChunkName: "component---src-templates-business-english-course-jsx" */),
  "component---src-templates-business-spanish-course-jsx": () => import("./../../../src/templates/business-spanish-course.jsx" /* webpackChunkName: "component---src-templates-business-spanish-course-jsx" */),
  "component---src-templates-contact-us-jsx": () => import("./../../../src/templates/contact-us.jsx" /* webpackChunkName: "component---src-templates-contact-us-jsx" */),
  "component---src-templates-evening-spanish-course-jsx": () => import("./../../../src/templates/evening-spanish-course.jsx" /* webpackChunkName: "component---src-templates-evening-spanish-course-jsx" */),
  "component---src-templates-exams-english-jsx": () => import("./../../../src/templates/exams-english.jsx" /* webpackChunkName: "component---src-templates-exams-english-jsx" */),
  "component---src-templates-extensive-english-course-jsx": () => import("./../../../src/templates/extensive-english-course.jsx" /* webpackChunkName: "component---src-templates-extensive-english-course-jsx" */),
  "component---src-templates-family-spanish-course-jsx": () => import("./../../../src/templates/family-spanish-course.jsx" /* webpackChunkName: "component---src-templates-family-spanish-course-jsx" */),
  "component---src-templates-faq-jsx": () => import("./../../../src/templates/faq.jsx" /* webpackChunkName: "component---src-templates-faq-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-intensive-english-course-jsx": () => import("./../../../src/templates/intensive-english-course.jsx" /* webpackChunkName: "component---src-templates-intensive-english-course-jsx" */),
  "component---src-templates-intensive-spanish-course-jsx": () => import("./../../../src/templates/intensive-spanish-course.jsx" /* webpackChunkName: "component---src-templates-intensive-spanish-course-jsx" */),
  "component---src-templates-methodology-and-levels-jsx": () => import("./../../../src/templates/methodology-and-levels.jsx" /* webpackChunkName: "component---src-templates-methodology-and-levels-jsx" */),
  "component---src-templates-online-courses-jsx": () => import("./../../../src/templates/online-courses.jsx" /* webpackChunkName: "component---src-templates-online-courses-jsx" */),
  "component---src-templates-our-team-jsx": () => import("./../../../src/templates/our-team.jsx" /* webpackChunkName: "component---src-templates-our-team-jsx" */),
  "component---src-templates-our-team-selected-jsx": () => import("./../../../src/templates/our-team-selected.jsx" /* webpackChunkName: "component---src-templates-our-team-selected-jsx" */),
  "component---src-templates-pricing-jsx": () => import("./../../../src/templates/pricing.jsx" /* webpackChunkName: "component---src-templates-pricing-jsx" */),
  "component---src-templates-private-english-course-jsx": () => import("./../../../src/templates/private-english-course.jsx" /* webpackChunkName: "component---src-templates-private-english-course-jsx" */),
  "component---src-templates-private-spanish-course-jsx": () => import("./../../../src/templates/private-spanish-course.jsx" /* webpackChunkName: "component---src-templates-private-spanish-course-jsx" */),
  "component---src-templates-reviews-jsx": () => import("./../../../src/templates/reviews.jsx" /* webpackChunkName: "component---src-templates-reviews-jsx" */),
  "component---src-templates-semi-intensive-spanish-course-jsx": () => import("./../../../src/templates/semi-intensive-spanish-course.jsx" /* webpackChunkName: "component---src-templates-semi-intensive-spanish-course-jsx" */),
  "component---src-templates-spanish-for-school-groups-on-language-trips-jsx": () => import("./../../../src/templates/spanish-for-school-groups-on-language-trips.jsx" /* webpackChunkName: "component---src-templates-spanish-for-school-groups-on-language-trips-jsx" */),
  "component---src-templates-student-portal-jsx": () => import("./../../../src/templates/student-portal.jsx" /* webpackChunkName: "component---src-templates-student-portal-jsx" */),
  "component---src-templates-student-visa-jsx": () => import("./../../../src/templates/student-visa.jsx" /* webpackChunkName: "component---src-templates-student-visa-jsx" */),
  "component---src-templates-summer-progam-course-jsx": () => import("./../../../src/templates/summer-progam-course.jsx" /* webpackChunkName: "component---src-templates-summer-progam-course-jsx" */),
  "component---src-templates-talk-to-coordinator-jsx": () => import("./../../../src/templates/talk-to-coordinator.jsx" /* webpackChunkName: "component---src-templates-talk-to-coordinator-jsx" */),
  "component---src-templates-terms-and-conditions-jsx": () => import("./../../../src/templates/terms-and-conditions.jsx" /* webpackChunkName: "component---src-templates-terms-and-conditions-jsx" */)
}

