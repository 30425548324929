module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Spanish Courses & Classes in Barcelona | Languages4Life","short_name":"Languages4Life","description":"Languages4Life is a modern, student-focused language school in the heart of Barcelona with friendly and professional native teachers to help you learn Spanish.","start_url":"/","background_color":"#28a35b","theme_color":"#28a35b","display":"minimal-ui","icon":"src/assets/images/favicon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"53163a4e149ddb6ad2c85b4447ddc3f9"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
